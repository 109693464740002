import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import Turbolinks from "turbolinks";
import Glide from "@glidejs/glide";
import LinkHover from "./hover";
import Dropdown from "./dropdown";
import StickyHeader from "./sticky";

import "../css/application.scss";
require.context("../images", true);

Rails.start();
ActiveStorage.start();
Turbolinks.start();

document.addEventListener("turbolinks:load", function() {
  new LinkHover("nav").init();

  new LinkHover("dropdown").init();

  if (document.querySelector("[data-link-group='flooring']")) {
    new LinkHover("flooring").init();
  }

  new Dropdown("flooring").init();
  new Dropdown("menu").init();

  new StickyHeader({
    scroll: 80
  }).init();

  const bannerSlider = new Glide("#bannerSlider", {
    type: "carousel",
    gap: 0,
    perView: 1,
    autoplay: 6000
  });

  const titleSlider = new Glide("#titleSlider", {
    type: "carousel",
    gap: 0,
    perView: 1,
    autoplay: 6000
  });

  if (document.querySelector("#titleSlider")) {
    titleSlider.mount();
    bannerSlider.on("run", event => {
      titleSlider.go(event.direction);
    });
  }

  if (document.querySelector("#bannerSlider")) {
    bannerSlider.mount();
    titleSlider.on("run", event => {
      bannerSlider.go(event.direction);
    });
  }

  if (document.querySelector("#brandsSlider")) {
    new Glide("#brandsSlider", {
      type: "carousel",
      perView: 6,
      autoplay: 4000,
      breakpoints: {
        1024: {
          perView: 3
        },
        640: {
          perView: 1
        }
      }
    }).mount();
  }

  if (document.querySelector("#showroomSlider")) {
    new Glide("#showroomSlider", {
      type: "carousel",
      perView: 1,
      gap: 32,
      peek: 250,
      breakpoints: {
        1024: {
          peek: 150
        },
        768: {
          peek: 0,
          autoplay: 4000
        }
      }
    }).mount();
  }


  if (document.querySelector("#flooringTypesSlider")) {
    var sliders = document.querySelectorAll('#flooringTypesSlider');

    for (var i = 0; i < sliders.length; i++) {
      var glide = new Glide(sliders[i], {
        type: "carousel",
        perView: 1,
        gap: 0,
        peek: 250,
        breakpoints: {
          1024: {
            peek: 150
          },
          768: {
            peek: 0,
            autoplay: 4000
          }
        }
      });

      glide.mount();
    }
  }

  if (document.querySelector("#typesSlider")) {
    new Glide("#typesSlider", {
      type: "carousel",
      gap: 0,
      perView: 3,
      breakpoints: {
        1024: {
          perView: 2
        },
        768: {
          perView: 1,
          autoplay: 4000
        }
      }
    }).mount();
  }
});
