function StickyHeader(attr) {
  this.scroll = attr.scroll;
}

StickyHeader.prototype.init = function() {
  const header = document.querySelector("[data-sticky-header]");
  const targets = document.querySelectorAll("[data-sticky-header-child]");
  const scroll = this.scroll;

  function headerPosition() {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > scroll) {
      targets.forEach(function(ele) {
        ele.classList.add("is-stuck");
      });
    } else {
      targets.forEach(function(ele) {
        ele.classList.remove("is-stuck");
      });
    }
  }
  window.addEventListener("scroll", () => headerPosition());
};

export default StickyHeader;
