function Dropdown(target) {
  this.target = target;
}

Dropdown.prototype.init = function() {
  const dropdown = document.querySelector(`[data-dropdown=${this.target}`);
  const dropdownMenu = document.querySelector(
    `[data-dropdown-child=${this.target}`
  );
  let isShown = false;

  dropdown.addEventListener("mouseover", function() {
    isShown = true;
    renderDropdownMenu();
  });

  dropdown.addEventListener("mouseleave", function() {
    isShown = false;
    renderDropdownMenu();
  });

  dropdown.addEventListener("touchstart", function(event) {
    event.preventDefault();
    isShown = !isShown;
    renderDropdownMenu();
  });

  function renderDropdownMenu() {
    isShown
      ? dropdownMenu.classList.add("is-show")
      : dropdownMenu.classList.remove("is-show");
  }
};

export default Dropdown;
