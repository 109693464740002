function LinkHover(target) {
  this.target = target;
}

LinkHover.prototype.init = function() {
  const linkGroup = document.querySelector(`[data-link-group=${this.target}`);
  const linkGroupLinks = [
    ...linkGroup.querySelectorAll(`[data-link-group-child=${this.target}`)
  ];

  linkGroupLinks.forEach(function(link, index) {
    let activeLink;
    link.addEventListener("mouseover", function() {
      activeLink = link;
      linkGroupLinks.forEach(function(link) {
        if (activeLink != link) {
          link.classList.add("link-inactive");
        }
      });
    });

    link.addEventListener("mouseleave", function() {
      linkGroupLinks.forEach(function(link) {
        link.classList.remove("link-inactive");
      });
    });
  });
};

export default LinkHover;
